import React, { useState } from 'react';
import { IconAt, IconMail, IconPhone } from '@tabler/icons';

export default function Header() {
    const [mail, setMail] = useState('mailto:no@spam.com');
    const [phone, setPhone] = useState('tel:0');
    return (
        <header className='flex pt-3 justify-between items-center text-sm'>
            <div>
                <a
                    className='hover:text-yellow-500 text-black mr-10 inline-block'
                    href={`${mail}`}
                    aria-label='Mail versenden'
                    onMouseOver={() =>
                        setMail('mailto:' + 'stiftung' + '@' + 'rogamus.de')
                    }
                >
                    <IconMail className='inline h-10 w-10 sm:h-5 sm:w-5 mr-2 stroke-1 sm:stroke-2' />
                    <span className='sm:inline text-baseline hidden'>
                        stiftung
                        <IconAt size={15} className='inline-block mx-0' />
                        rogamus.de
                    </span>
                </a>

                <a
                    className='hover:text-yellow-500 text-black'
                    href={phone}
                    aria-label='Anrufen'
                    onMouseOver={() => setPhone('tel:' + '022116427501')}
                >
                    <IconPhone className='inline h-10 w-10 sm:h-5 sm:w-5 mr-2 mb-1 stroke-1 sm:stroke-2' />
                    <span className='hidden sm:inline text-baseline'>
                        0221 1642 7501
                    </span>
                </a>
            </div>
            <div>
                <a
                    className='hover:text-yellow-500 text-black'
                    href='https://de-de.facebook.com/Rogamus-Gebetsgemeinschaft-um-geistliche-Berufungen-im-Erzbistum-K%C3%B6ln-232112666813566/'
                    aria-label='Facebookseite'
                >
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                        fill='none'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='inline h-10 w-10 sm:h-7 sm:w-7 mb-1 mr-10 sm:mr-5 stroke-1 sm:stroke-1.5'
                    >
                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                        <path d='M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3' />
                    </svg>
                </a>

                <a
                    className='hover:text-yellow-500 text-black'
                    href='https://www.instagram.com/rogamus/'
                    aria-label='Instagramseite'
                >
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                        fill='none'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='inline h-10 w-10 sm:h-7 sm:w-7 mb-1 stroke-1 sm:stroke-1.5'
                    >
                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                        <rect x='4' y='4' width='16' height='16' rx='4' />
                        <circle cx='12' cy='12' r='3' />
                        <line x1='16.5' y1='7.5' x2='16.5' y2='7.501' />
                    </svg>
                </a>
            </div>
        </header>
    );
}
