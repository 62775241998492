import { Link } from 'gatsby';
import React from 'react';

export default function HeaderImage() {
    return (
        <Link to='/'>
            <img
                src='https://content.rogamus.de/wp-content/uploads/rogamus-1.jpg'
                alt='Logo der rogamus Stiftung'
                aria-label='Startseite'
                className='pb-4 mx-auto h-48 '
            />
        </Link>
    );
}
