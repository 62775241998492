import React from 'react';
import Footer from './Footer';
import Header from './Header';
import HeaderImage from './HeaderImage';
import Navigation from './Navigation';

export default function Container({ children }) {
    return (
        <>
            <div className='container mx-auto lg:max-w-screen-lg px-3 sm:p-0'>
                <Header></Header>
                <HeaderImage></HeaderImage>
                <Navigation></Navigation>
                <div className='pt-5 pb-20 sm:py-20'>{children}</div>
            </div>
            <Footer></Footer>
        </>
    );
}
