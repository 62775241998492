import { Link } from 'gatsby';
import React from 'react';
import { IconCopyright } from '@tabler/icons';

export default function Footer() {
    return (
        <footer className='bg-gray-900 w-full py-20'>
            <div className='px-4 container mx-auto lg:max-w-screen-lg grid grid-cols-1 sm:grid-cols-3 gap-14 text-center'>
                <div className='text-center'>
                    <img
                        className='mx-auto text-center'
                        src='https://rogamus.de/wp-content/uploads/erbistum.png'
                        alt=''
                        width='200px'
                    />
                    <p className='my-6 text-gray-500 text-sm'>
                        <IconCopyright
                            size={15}
                            className='inline-block mb-1'
                        />{' '}
                        Erzbistum Köln {new Date().getFullYear()}
                    </p>
                    <small className='text-white'>
                        <Link
                            className='text-white nounderline'
                            to='/impressum'
                        >
                            Impressum
                        </Link>
                        {' | '}
                        <Link
                            className=' text-white nounderline'
                            to='/datenschutz'
                        >
                            Datenschutz
                        </Link>
                    </small>
                </div>
                <div className='text-center block'>
                    <img
                        alt=''
                        className='img-responsive text-center mx-auto mb-3'
                        src='https://rogamus.de/wp-content/uploads/berufen-Logo_wei%C3%9F.png'
                        width='70px'
                    />
                    <a
                        className='text-white text-sm nounderline'
                        href='https://berufen.de'
                        target='_blank'
                        rel='noreferrer'
                    >
                        www.berufen.de
                    </a>
                </div>
                <div className='text-center '>
                    <small className='text-white mb-2 block'>Folge uns:</small>
                    <div className='text-center mx-auto'>
                        <a
                            className='hover:text-yellow-500 text-white inline-block'
                            href='https://de-de.facebook.com/Rogamus-Gebetsgemeinschaft-um-geistliche-Berufungen-im-Erzbistum-K%C3%B6ln-232112666813566/'
                            aria-label='Facebookseite'
                        >
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 24 24'
                                strokeWidth={1.5}
                                stroke='#111827'
                                fill='currentColor'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='inline h-10 w-10 mb-1 mr-10 sm:mr-5'
                            >
                                <path
                                    stroke='none'
                                    d='M0 0h24v24H0z'
                                    fill='none'
                                />
                                <path d='M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3' />
                            </svg>
                        </a>

                        <a
                            className='hover:text-yellow-500 text-white inline-block'
                            href='https://www.instagram.com/rogamus/'
                            aria-label='Instagramseite'
                        >
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 24 24'
                                strokeWidth={1.5}
                                stroke='#111827'
                                fill='currentColor'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='inline color-white h-10 w-10 mb-1'
                            >
                                <path
                                    stroke='none'
                                    d='M0 0h24v24H0z'
                                    fill='none'
                                />
                                <rect
                                    x='4'
                                    y='4'
                                    width='16'
                                    height='16'
                                    rx='4'
                                />
                                <circle cx='12' cy='12' r='3' />
                                <line x1='16.5' y1='7.5' x2='16.5' y2='7.501' />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}
