import { Link } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { IconMenu2 } from '@tabler/icons';

function NavItem({ text, uri }) {
    return (
        <li className='block sm:inline-block p-0 m-0'>
            <Link
                to={uri}
                className='relative inline-block py-4 sm:py-2 px-3 md:mx-8 font-medium text-black hover:text-yellow-500'
            >
                {text}
            </Link>
        </li>
    );
}

function NavDropdownItem({ text, uri }) {
    return (
        <li className='block p-0 m-0 hover:bg-gray-50'>
            <Link
                to={uri}
                className='relative block py-3 px-8 font-normal text-left text-black hover:text-yellow-500'
            >
                {text}
            </Link>
        </li>
    );
}

function NavDropdown({ text, children }) {
    const [hidden, setHidden] = useState(true);

    const dropdownRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setHidden(true);
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <li ref={dropdownRef} className='block sm:inline-block p-0 m-0'>
            <div
                className='cursor-pointer inline-block py-4 sm:py-2 px-3 md:mx-8 font-medium text-black hover:text-yellow-500'
                onClick={() => {
                    setHidden(!hidden);
                }}
            >
                {text}
            </div>
            {!hidden && (
                <ul className='sm:absolute bg-white rounded-lg mt-3 py-3 px-0 shadow-navbar'>
                    {children}
                </ul>
            )}
        </li>
    );
}

function NavButton({ text, uri }) {
    return (
        <li className='inline-block p-0 m-0 py-2 sm:py-0'>
            <Link className='sm:ml-3 md:ml-6 btn' to={uri}>
                {text}
            </Link>
        </li>
    );
}

export default function Navigation() {
    const [showMobile, setShowMobile] = useState(true);
    useEffect(() => {
        setShowMobile(false);
    }, []);
    return (
        <div className='mx-auto w-auto block text-center bg-white'>
            <button
                className='sm:hidden text-center mx-auto block'
                onClick={() => setShowMobile(!showMobile)}
                aria-label='Menü'
            >
                <IconMenu2 size={40} />
            </button>
            <ul
                className={
                    'm-0 list-none p-2 mx-auto mt-3 rounded-xl text-center shadow-navbar sm:inline-block ' +
                    (!showMobile && 'hidden')
                }
            >
                <NavItem text='Anbetungskapelle' uri='/anbetungskapelle' />
                <NavItem text='Stiftung' uri='/stiftung' />

                <NavDropdown text='Gebetsgemeinschaft'>
                    <NavDropdownItem
                        text='Beten wir um Berufungen'
                        uri='/gebetsgemeinschaft'
                    />
                    <NavDropdownItem text='Über Rogamus' uri='/ueber-rogamus' />
                    <NavDropdownItem
                        text='Jetzt mitbeten'
                        uri='/jetzt-mitbeten'
                    />
                </NavDropdown>
                <NavButton text='Jetzt Spenden' uri='/spenden' />
            </ul>
        </div>
    );
}
